import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import wr from '../audios/wrong.mp3';
import right from '../audios/right.mp3';
import loss from '../audios/tryagain.mp3';
import win from '../audios/win.wav';
import InQuestionAd from "./InQuestionAd";


const Questions = ({
    currQues,setCurQues,questions,options,correct,score,setScore,setQuestions
    }) =>{

  useEffect(() => {
  },[questions,currQues]);

  const playRight = () => {
    new Audio(right).play()
  }

  const playWrong = () => {
    new Audio(wr).play()
  }

  const playWin = () => {
    new Audio(win).play()
  }

  const playLost = () => {
    new Audio(loss).play()
  }

  const div = useRef(null)

  const [corOpt, setCorOpt] = useState(0);
  const [wrong, setWrong] = useState(0);
  const [showAd, setShowAd] = useState(false);

  const [selected,setSelected] = useState();
  const [error,setError] = useState(false);
  const alpha = ["A","B","C","D"];
  const navigate = useNavigate();
  
   const handleSkip = () =>{
      setShowAd(false)
   } 
   const handleNext = (option) => {
    

    let ans = questions[currQues].answer;

        if(option==ans){    
            playRight()
            setScore(score+1)
            setCorOpt(option)
        }else{
            playWrong()
            setWrong(option)   
            setCorOpt(ans)             
        }
        
        setTimeout(()=>{
            
            if(currQues>=(questions.length-1)){
                
                if(score<3) {
                  playLost()
                  navigate('/lost')
                }
                else { 
                  playWin()
                  navigate('/win')
                }

            }else{
                setCurQues(currQues+1)
            }
             
            if(currQues==2) {
              setShowAd(true)
            }
            
            setWrong(0)   
            setCorOpt(0)       

        },1000)
       
   }

    return (
        <>

            <div className="questionTile mb-5 mt-4">
                <div className='wire w-l'></div>
                    <div className='tile qTile'>
                        <span style={{fontSize:questions[currQues].question.length<50 ? '1.2rem' : '.9rem'}} className='text qText'>{questions[currQues].question}</span>
                    </div>
                <div className='wire w-r'></div>
            </div>
            {
                options && options.map((option,index) => (

                    index%2==0 ? (
                        <div key={index} onClick={()=>handleNext(index+1)} 
                        className="optionTile mb-4">
                        
                         <div className='tile' style={{
                         backgroundColor: wrong != (index+1) ? corOpt==(index+1) ? '#00f20a' : '' : '#ff2b2b',
                       }}>
                         <span className='text'>{alpha[index]}:</span>
                         <span className='option'>{option}</span>
                       
                         </div>
                         <div className='wire w-r'></div>
                     </div>
                        ): (
                            <div key={index} onClick={()=>handleNext(index+1)} 
                           className="optionTile mb-4">
                            <div className='wire w-l'></div>
                            <div className='tile' style={{
                            backgroundColor: wrong != (index+1) ? corOpt==(index+1) ? '#00f20a' : '' : '#ff2b2b',
                          }}>
                            <span className='text'>{alpha[index]}:</span>
                            <span className='option'>{option}</span>
                            </div>
                        </div>
                        )

                    )
                )
            }

            {
              showAd == true ? (
                  <>
                  <div style={{backgroundColor:"#1ea3fb",width:"100%",top:"50px",height:"100%",position:"absolute",zIndex:"1111111"}}>
                    <InQuestionAd slot="7329993128"/>
                    <br/>
                    <br/>
                     <button style={{height: "3rem",marginTop:"1rem",width:"50%",fontSize:"1.2rem"}} onClick={handleSkip} className='tile text-decoration-none text-dark'>
                      <span className='text' style={{fontSize:'1rem;'}}>Skip</span>
                    </button>
                  </div>
                  </>
                ):(
                    <>
                    </>
                )
            }
            


           



        </>
    )
}
export default Questions;