import axios from "axios";
import { Link, Route, useNavigate } from "react-router-dom";
import InArticalAd from "./InArticalAd";
import { Cookies, useCookies } from "react-cookie";
import { useEffect, useState } from "react";


function Win({name,score,setScore,fetchQuestions,currentlevel,}) {

  const [qId,setQId] = useState(0);
  const [cookies, setCookie] = useCookies(["games"]);

  const navigate = useNavigate();

  const handleLevel = () =>{
      setScore(0)
      let lev = currentlevel+1;
      
      if(currentlevel=='3'){
        
        let qCount = cookies.games;
        
        setQId(qCount)
        if(qCount==3)
           qCount = 1
        else 
          qCount++;
  
          let expires = new Date();
          expires.setTime(expires.getTime() + 1000 * 1000);
         
          setCookie("games", qCount, { path: "/", expires });

          window.location='/'

      }else{
        fetchQuestions('1',lev)
        navigate(`/mcq`)
      }

     
  }

  const HandleSubmit = async () => {
    const success = await axios.get(`https://games.jobaaj.com/api/result?name=${name}&score=${score}&status=win&qset=${qId}`);
  }

  HandleSubmit();

  return (
     <>
      <div className='quizbox'>

        <div className='container  mt-5'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
          <span className='blue-box mb-b'><span>GREAT!</span></span>
          <br/>
          <br/>
          <h4  className='score'>Score : {score}</h4>
          <img className='banner' style={{marginBottom:"2rem"}}  src='win.png' />

          <InArticalAd slot="7329993128"/> 
          <br/>
          <br/>

          <div className="startBtn">
              <div className='wire w-l'></div>
              <button onClick={handleLevel} className='tile text-decoration-none text-dark'>
                <span className='text' style={{fontSize:'1.1rem'}}>Next Level</span>
              </button>
              <div className='wire w-r'></div>
          </div>

          <br/>
          <br/>
          

          <div className="d-flex retry">

          <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/chat.png"/>
                </span>
              </a>

              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/cancel.png"/>
                </span>
              </a>

              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/return.png"/>
                </span>
              </a>

          </div>
        </div>

        </div>
      </div>
     </>
  );
}

export default Win;
