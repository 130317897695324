import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import audio from '../audios/start.mp3';
import level from '../audios/level.wav';
import { Cookies, useCookies } from "react-cookie";


const Start = ({name,setName,fetchQuestions,setCurrentLevel}) => {

  const playStart = () => {
    new Audio(audio).play()
  }

  useEffect(() => {
    
    if (!cookies.games) {
     
      setQId(1)
     
      let expires = new Date();
      expires.setTime(expires.getTime() + 1000 * 1000);
      setCookie("games", "1", { path: "/", expires });
    
    }else{
      setQId(cookies.games);
    }
  }, []);

  const playLevelChange = () => {
    new Audio(level).play()
  }

  const [cookies, setCookie] = useCookies(["games"]);
  const [qId,setQId] = useState(0);
  const [isError,setError] = useState(false);
  const navigate = useNavigate();
  const handleStart = () => {

      if(!name) {
        setError(true);
        return;
      }else{
        setError(false);
        
        setName(name)
        playStart()

        setCurrentLevel(isLevel)

        fetchQuestions(qId,isLevel)
        
        navigate(`/mcq`)
      }
  }

  const [isLevel,setisLevel] = useState(1);

  const handleLevel = (level) => {
    playLevelChange()
    setisLevel(level)
  }

  return (
     <>
          <div className='d-flex flex-column justify-content-center align-items-center'>
        
          <img alt="banner" className='banner mt-3' style={{marginBottom:'1rem',width:'15rem'}} src='quiz.webp' />

          <TextField
              variant="standard"
              error={isError}
              helperText={isError && "Add your name!"}
              // InputProps={{
               
              // }}
              sx={{
                "& .MuiInputLabel-root": { color: "black" },
                borderBottom: "1px solid black",
                borderRadius: .5
              }}

              InputProps={{ disableUnderline: true,
                inputProps: {
                style: {
                  fontSize: '1.5rem',
                  color: 'white',
                  fontWeight:'700',
                  textTransform: 'capitalize',
                  textAlign:'center'
                },}}}

              style={{ marginBottom: '2rem',borderBottomColor: 'white', }}
              label="Your Name"
              onChange={(e)=>setName(e.target.value)}
            />

            <div className="level d-flex flex-column " style={{ marginBottom: '3rem',}}>
                <button onClick={() => handleLevel(1)} className={isLevel === 1 ? 'active' : ''}>
                  Easy {isLevel === 1 ? '✓' : ''} 
                </button>
                <button onClick={() => handleLevel(2)} className={isLevel === 2 ? 'active' : ''} >
                  Medium  {isLevel === 2 ? '✓' : ''} 
                </button>
                <button onClick={() => handleLevel(3)} className={isLevel === 3 ? 'active' : ''}>
                  Hard {isLevel === 3 ? '✓' : ''} 
                </button>
            </div>

          <div className="startBtn">
              <div className='wire w-l'></div>
              <div onClick={handleStart} className='tile text-decoration-none text-dark'>
                <span className='text'>START</span>
              </div>
              <div className='wire w-r'></div>
          </div>
        </div>
       
     </>
  );
}

export default Start;
