import { Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ChoiceMCQ from './comps/ChoiceMCQ';
import Start from './comps/Start';
import Win from './comps/Win';
import Lost from './comps/Lost';
import { useState } from 'react';
import axios from 'axios';

function App() {

  const [name,setName] = useState("");
  const [questions,setQuestions] = useState();
  const [currentlevel,setCurrentLevel] = useState(0);
  const [score,setScore] = useState(0);

  const fetchQuestions  = async(category="",level="")=>{
    console.log("levels " + level)
    setCurrentLevel(level)
    const { data } = await axios.get(`https://games.jobaaj.com/api/quiz?quiz=${category}&level=${level}`);
    setQuestions(data.results);
  }
  
  return (
     <>
    <div className='quizbox'>
    <div className='container'>
      <div className='d-flex justify-content-center align-items-center'>
    
          <Routes>
            <Route path="/" element={<Start name={name} setName={setName} fetchQuestions={fetchQuestions} setCurrentLevel={setCurrentLevel} />}  />
            <Route path="/mcq"  element={<ChoiceMCQ name={name} score={score} setScore={setScore} questions={questions}  setQuestions={setQuestions}/>} />
            <Route path="/win" element={<Win  name={name} score={score}  setScore={setScore}  fetchQuestions={fetchQuestions}  currentlevel={currentlevel} />} />
            <Route path="/lost" element={<Lost name={name} score={score}/>} />
          </Routes>
     
        </div>

      </div>
    </div>
     </>
  );
}

export default App;
