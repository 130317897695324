import axios from "axios";
import { Link, Route, useNavigate } from "react-router-dom";
import InArticalAd from "./InArticalAd";
import { Cookies, useCookies } from "react-cookie";

function Lost({score,name}) {

  const [cookies, setCookie] = useCookies(["games"]);

  const HandleSubmit = async () => {

    let qId = cookies.games;

    const success = await axios.get(`https://games.jobaaj.com/api/result?name=${name}&score=${score}&status=lost&qset=${qId}`);
  }

  HandleSubmit();

  return (
     <>
          <div className='d-flex flex-column mt-5 justify-content-center align-items-center'>
          <span className='blue-box mb-b'><span>Oh no!</span></span>
          <br/>
          <br/>
          <h4  className='score'>Score : {score}</h4>
    
          <img className='banner  mt-5'  src='lost.png' />

					<InArticalAd slot="7329993128"/> 
          <br/>
          <br/>

          <div className="startBtn">
              <div className='wire w-l'></div>
              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text' style={{fontSize:'1rem'}}>TRY AGAIN</span>
              </a>
              <div className='wire w-r'></div>
          </div>


          <br/><br/>
          <div className="d-flex retry mt-5">
         
              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/chat.png"/>
                </span>
              </a>

              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/cancel.png"/>
                </span>
              </a>

              <a href="/" className='tile text-decoration-none text-dark'>
                <span className='text icon'>
                  <img src="/return.png"/>
                </span>
              </a>

          </div>
          <br/><br/>

        </div>

     
     </>
  );
}

export default Lost;
