import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Questions from "./Questions";


const ChoiceMCQ = ({name,score,setScore,questions,setQuestions}) => {
  
  const [currQues,setCurQues] = useState(0);
  const [options,setOptions] = useState();

  useEffect(()=>{
        
      setOptions(questions && handleShuffle([
                 ...questions[currQues]?.options,
                ]))
      
  },[questions,currQues]);

  const handleShuffle = (options) =>{
      return options;
  }

  return (
       <>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              
            <img className="img-c pt-2" src="https://static.vecteezy.com/system/resources/previews/017/784/935/original/idea-brain-icon-on-transparent-background-free-png.png"/>

           {questions &&
            questions ? (
                <>
                <Questions 
                  currQues = {currQues}
                  setCurQues = {setCurQues}
                  questions={questions}
                  options = {options}
                  correct = {questions[currQues]?.correct_answers}
                  score = {score}
                  setScore = {setScore}
                  setQuestions = {setQuestions}
                />
                </>
            ): (
               <CircularProgress  style={{margin:200,'color': 'white'}} />
            )
           }

            </div>
  
       </>
    );
  }
  
  export default ChoiceMCQ;
  